import React from "react";
import { CircularProgress } from "@material-ui/core";

export default function AlertDialog({ show }) {
  return (
    <>
      {show && (
        <div className="loader-container-fullpage">
          <div className="loader-fullpage">
            <CircularProgress
              style={{ justifyContent: "center", color: "#E87722" }}
            />
          </div>
        </div>
      )}
    </>
  );
}
