// const sessionexpired = () => {
//   sessionStorage.clear();
//   sessionStorage.setItem("isAuthenticated", JSON.stringify(false));
//   window.location = "/notauthorized";
// };

const resolvePromise = async promise => {
  const response = {
    data: null,
    error: null
  };

  try {
    response.data = await promise;
  } catch (error) {
    response.error = error;
    console.log("response error " + response.error);
    // if(error.response.status === 401)
    //     sessionexpired();
  }
  return response.data;
};

export default resolvePromise;
