import React, { useState, useEffect } from "react";
import { Grid, Box, Paper } from "@material-ui/core";
import Controls from "../controls/Controls";
import { useForm, Form } from "../controls/useForm";
import { useNavigate } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Typography, DialogTitle, makeStyles } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { toast } from "react-toastify";
import { getExternalPBMs } from "../services/PerksServices";

const useStyles = makeStyles(theme => ({
  dialogWrapper: {
    padding: theme.spacing(1),
    position: "absolute",
    top: theme.spacing(5)
  },
  dialogTitle: {
    paddingRight: "10px",
    color: "#002677"
  }
}));

export default function ViewPBM(props) {
  const classes = useStyles();
  const [pbmData, setPbmData] = useState();
  const { addOrEdit, recordForEdit } = props;
  const { onClose } = props;
  const [isLoading, setIsLoading] = useState(false);

  const initialFValues = {
    extPBMId: props.pbmRowData[1],
    extPBMName: props.pbmRowData[2],
    extPBMUUID: props.pbmRowData[3],
    fromDate: props.pbmRowData[4],
    thruDate: props.pbmRowData[5],
    addDate: props.pbmRowData[6],
    modifiedDate: props.pbmRowData[7],
    user: props.pbmRowData[8],
    status: props.pbmRowData[9] == "A" ? "Active" : "Inactive",
    bin: props.pbmRowData[10],
    pcn: props.pbmRowData[11],
    group: props.pbmRowData[12],
    disocuntCHID: props.pbmRowData[13]
  };

  const toasterConfig = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={true}
      PaperProps={{ variant: "elevation14" }}
    >
      <Paper>
        {/* <Form onLoad={handleLoad}> */}
        <Form>
          <DialogTitle className={classes.dialogTitle}>
            <div style={{ display: "flex" }}>
              <br></br>
              <Typography variant="h6" component="div" style={{ flexGrow: 5 }}>
                Display PBM
              </Typography>
            </div>
          </DialogTitle>

          <DialogContent
            dividers
            PaperProps={{
              variant: "elevation14",
              sx: { width: "150%", maxHeight: 300 }
            }}
          >
            <Grid container>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <Controls.Input
                    name="extPBMId"
                    label="Ext PBM ID*"
                    value={initialFValues.extPBMId}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Input
                    name="extPBMName"
                    label="Ext PBM Name*"
                    value={initialFValues.extPBMName}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Input
                    name="extPBMUUID"
                    label="Ext PBM UUID*"
                    value={initialFValues.extPBMUUID}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Input
                    name="fromDate"
                    label="From Date*"
                    value={initialFValues.fromDate}
                    InputProps={{
                      readOnly: true
                    }}
                    type="date"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Input
                    name="thruDate"
                    label="Thru Date*"
                    value={initialFValues.thruDate}
                    InputProps={{
                      readOnly: true
                    }}
                    type="date"
                  />
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <Grid item xs={12}>
                  <Controls.Input
                    name="bin"
                    label="BIN"
                    value={initialFValues.bin}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Input
                    name="pcn"
                    label="PCN"
                    value={initialFValues.pcn}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Input
                    name="group"
                    label="Group"
                    value={initialFValues.group}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Input
                    name="disocuntCHID"
                    label="Ext Disc CHID"
                    value={initialFValues.disocuntCHID}
                    InputProps={{
                      readOnly: true
                    }}
                  />

                  <Grid item xs={12}>
                    <Controls.Input
                      name="status"
                      label="Status"
                      value={initialFValues.status}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                </Grid>

                <Container>
                  <br></br>
                  <Controls.Button
                    text="CANCEL"
                    color="default"
                    onClick={() => onClose()}
                  />
                </Container>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogContent dividers></DialogContent>
        </Form>
      </Paper>
    </Dialog>
  );
}
