import React, { Component } from "react";

function WelcomePage() {
  return (
    <div className="home1">
      <h1>Welcome</h1>

      <span>Welcome to RxClaim Optum Perks application</span>

      <br></br>

      <br></br>

      <span>
        This feature will provide the mechanism to allow simple multi-PBM cash
        card usage such that members get the benefit of multi-PBM pricing.{" "}
      </span>
    </div>
  );
}

export default WelcomePage;
