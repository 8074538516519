import React, { useState, useEffect } from 'react'
import { Grid, Box, Paper } from '@material-ui/core';
import Controls from "../controls/Controls"
import { useForm, Form } from "../controls/useForm"
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Typography, DialogTitle, makeStyles } from '@material-ui/core'
import { Container } from '@material-ui/core';
import { toast } from "react-toastify";
import { addExternalPBM } from "../services/PerksServices";
import PerksPageLoader from "../components/PerksPageLoader";
import DialogActions from "@mui/material/DialogActions";

const initialFValues = {
  extPBMId: '',
  extPBMName: '',
  extPBMUUID: '',
  fromDate: new Date(),
  thruDate: new Date(),
  bin: '',
  pcn: '',
  group: '',
  disocuntCHID: ''
  // user: JSON.parse(sessionStorage.getItem("user-name")),
  // changeUser: JSON.parse(sessionStorage.getItem("user-name"))
}


const useStyles = makeStyles(theme => ({
  dialogWrapper: {
    padding: theme.spacing(1),
    position: 'absolute',
    top: theme.spacing(5)
  },
  dialogTitle: {
    paddingRight: '10px',
    color: '#002677'

  }
}))




export default function AddPBM(props) {



  const classes = useStyles();
  const { addOrEdit, recordForEdit } = props
  const { onClose, open, onSubmit } = props;
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState();

  useEffect(() => {
    setUsername(JSON.parse(sessionStorage.getItem("user-name")))
  }, []);

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('extPBMId' in fieldValues)
      temp.extPBMId = fieldValues.extPBMId ? "" : "This field is required."
    if ('extPBMName' in fieldValues)
      temp.extPBMName = fieldValues.extPBMName ? "" : "This field is required."
    if ('extPBMUUID' in fieldValues)
      temp.extPBMUUID = (/^[a-z\d\-]+$/i).test(fieldValues.extPBMUUID) ? "" : "This field is required. Valid values A-Z, a-z, 0-9, -"
    if ('fromDate' in fieldValues)
      temp.fromDate = fieldValues.fromDate ? "" : "This field is required."
    if ('thruDate' in fieldValues)
      temp.thruDate = fieldValues.thruDate ? "" : "This field is required."
    // if (values.fromDate > values.thruDate)
    //   temp.thruDate = "Thru Date cannot be less than From date."
    if (values.fromDate > values.thruDate)
    {
      temp.thruDate = "Thru Date cannot be less than From date."
    }
    if ('bin' in fieldValues)
      temp.bin = isNaN(fieldValues.bin) ? "This field should be numeric" : fieldValues.bin.length > 5 || fieldValues.bin.length == 0 ? "" : "This field should have minimum 6 digits."



    setErrors({
      ...temp
    });


    temp.extPBMId == "" && temp.extPBMName == "" && temp.extPBMUUID == ""

      ? setEnableSubmit(true) : setEnableSubmit(false);

    if (fieldValues == values) return Object.values(temp).every(x => x == "");
  }

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,

  } = useForm(initialFValues, true, validate);

  const handleSubmit = e => {
    e.preventDefault()
    if (validate()) {

      setLoading(true);
      console.log("values object" + values);
      const extPBMToSave = {
        extPbmId: values.extPBMId,
        extPbmName: values.extPBMName,
        extPbmUuid: values.extPBMUUID,
        fromDate: values.fromDate,
        thruDate: values.thruDate,
        xp3BinNumber: values.bin,
        xp3ControlNumber: values.pcn,
        xp3GroupNumber: values.group,
        xp3DiscountCard: values.disocuntCHID,
        user: username,
        changeUser: username
      };

      console.log("About to save pbm list " + extPBMToSave.fromDate);
      console.log("About to save pbm list " + extPBMToSave.user);
      addExternalPBM(extPBMToSave).then(function (axiosResult) {
        console.log("axios result from Add External Pbm " + axiosResult);
        if (axiosResult !== null && axiosResult.data !== null) {
          console.log("Add PBM response.JSON:", {
            message: "Post successful",
            data: axiosResult
          });
          setLoading(false);
          handleClose();
          onSubmit();
          toast.success(
            `External PBM ID -  ${extPBMToSave.extPbmId} Added Successfully`,
            toasterConfig
          );
        } else {
          setLoading(false);
          toast.error(
            "Error while saving new External PBM ID",
            toasterConfig
          );
        }
      });

    }
  };


  const toasterConfig = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit
      })
  }, [recordForEdit])


  return (
    <Dialog

      fullWidth
      maxWidth="md"
      open={true}
      PaperProps={{ variant: "elevation14" }}


    >
      <Paper>
        <Form onSubmit={handleSubmit}>

          <DialogTitle className={classes.dialogTitle}>

            <div style={{ display: 'flex' }}>
              <br></br>
              <Typography variant="h6" component="div" style={{ flexGrow: 5 }}>
                Add New PBM
              </Typography>

            </div>
          </DialogTitle>

          <DialogContent dividers PaperProps={{ variant: "elevation14", sx: { width: "150%", maxHeight: 300 } }} >

            <Grid container>
              <Grid item xs={6}>

                <Grid item xs={12}>
                  <Controls.Input
                    name="extPBMId"
                    label="Ext PBM ID*"
                    inputProps={{
                      maxLength: 10,
                      style: { textTransform: "uppercase" }
                    }}
                    value={values.extPBMId}
                    onChange={handleInputChange}
                    error={errors.extPBMId}

                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Input
                    name="extPBMName"
                    label="Ext PBM Name*"
                    inputProps={{
                      maxLength: 25,
                      textTransform: "uppercase"
                    }}
                    value={values.extPBMName}
                    onChange={handleInputChange}
                    error={errors.extPBMName}
                  />

                </Grid>
                <Grid item xs={12}>
                  <Controls.Input
                    name="extPBMUUID"
                    label="Ext PBM UUID*"
                    inputProps={{
                      maxLength: 36
                    }}
                    value={values.extPBMUUID}
                    onChange={handleInputChange}
                    error={errors.extPBMUUID}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controls.DatePicker
                    name="fromDate"
                    label="From Date*"
                    value={values.fromDate}
                    onChange={handleInputChange}
                    error={errors.fromDate}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controls.DatePicker
                    name="thruDate"
                    label="Thru Date*"
                    value={values.thruDate}
                    onChange={handleInputChange}
                    error={errors.thruDate}
                  />
                </Grid>


              </Grid>
              <Grid item xs={6}>

                <Grid item xs={12}>
                  <Controls.Input

                    inputProps={{
                      // minLength: 6,
                      maxLength: 6
                    }}
                    name="bin"
                    label="BIN"
                    value={values.bin}
                    onChange={handleInputChange}
                    error={errors.bin}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controls.Input
                    name="pcn"
                    label="PCN"
                    inputProps={{
                      maxLength: 10,
                      textTransform: "uppercase"
                    }}
                    value={values.pcn}
                    onChange={handleInputChange}

                  />
                </Grid>
                <Grid item xs={12}>
                  <Controls.Input
                    name="group"
                    label="Group"
                    inputProps={{
                      maxLength: 15,
                      textTransform: "uppercase"
                    }}
                    value={values.group}
                    onChange={handleInputChange}

                  />
                </Grid>
                <Grid item xs={12}>
                  <Controls.Input
                    name="disocuntCHID"
                    label="Ext Disc CHID"
                    inputProps={{
                      maxLength: 20
                    }}
                    value={values.disocuntCHID}
                    onChange={handleInputChange}

                  />

                </Grid>
                <Container>
                <br></br> <br></br> <br></br> <br></br> <br></br> <br></br>{" "}
                  <br></br>
                  <Controls.Button
                    type="submit"
                    variant="contained"
                    autofocus
                    disabled={!enableSubmit}
                    text="SUBMIT"
                  />
                  <Controls.Button
                    text="CANCEL"
                    color="default"
                    onClick={() => onClose()}
                  />

                  <PerksPageLoader show={loading} />
                </Container>

              </Grid>
            </Grid>

          </DialogContent>
          <DialogContent dividers >

          </DialogContent>
        </Form>
      </Paper>
    </Dialog>
  )
}



