import React, { useEffect, useState } from "react";
import { getExternalPBMList } from "../services/PerksServices";
import MUIDataTable from "mui-datatables";
import getMuiTheme from "../assets/muitheme";
import { ThemeProvider } from "@mui/material/styles";
import PerksPageLoader from "../components/PerksPageLoader";
import "react-toastify/dist/ReactToastify.css";
import { Grid } from "@mui/material";
import AddButton from "../components/AddButton";
import Divider from "@mui/material/Divider";
import AddExternalPBMList from "../container/AddExternalPBMList";
import PerksTooltip from "../components/PerksTooltip.js";
import DisplayExternalPBMList from "./DisplayExternalPBMList";
import EditExternalPBMList from "./EditExternalPBMList";
import ProductsDialog from "../container/ProductsDialog";

function ExternalPBMListDatatable(props) {
  const [pbmListData, setPbmListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddPBMListDialogPage, setShowAddPBMListDialogPage] = useState(
    false
  );
  const [refreshListData, setRefreshListData] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedRowsToSubmit, setSelectedRowsToSubmit] = useState([]);
  const [
    showDisplayPBMListDialogPage,
    setShowDisplayPBMListDialogPage
  ] = useState(false);
  const [currentlySelectedRow, setCurrentlySelectedRow] = useState([]);
  const [showEditPBMListDialogPage, setShowEditPBMListDialogPage] = useState(
    false
  );

  const [showProductsDialogPage, setShowProductsDialogPage] = useState(false);

  const [hasFullAccess, setHasFullAccess] = useState(false);
  
  const handleEditClick = rowData => {
    console.log("handle edit click " + rowData);
    setShowEditPBMListDialogPage(true);
    setCurrentlySelectedRow(rowData);
  };

  const handleViewClick = rowData => {
    setShowDisplayPBMListDialogPage(true);
    setCurrentlySelectedRow(rowData);
    console.log("handle view click " + rowData);
  };

  const handleProductsClick = rowData => {
    setShowProductsDialogPage(true);
    setCurrentlySelectedRow(rowData);
  }

  const handleRowClick = (rowData, rowMeta) => {
    console.log("Selected Row Data");
    console.log(rowData, rowMeta);
  };

  const handleDisplayClose = () => {
    setShowDisplayPBMListDialogPage(false);
  };

  const handleEditClose = () => {
    setShowEditPBMListDialogPage(false);
  };

  const handleProductsClose = () => {
    setShowProductsDialogPage(false);
  };

  const columns = [
    {
      name: " ",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <PerksTooltip
              rowData={tableMeta.rowData}
              handleEditClick={handleEditClick}
              handleViewClick={handleViewClick}
              handleProductsClick={handleProductsClick}
              hasFullAccess={hasFullAccess}
            />
          );
        }
      }
    },
    {
      name: "pbmListId",
      label: "Ext PBM List",
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "pbmListName",
      label: "Ext PBM List Name",
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "addDate",
      label: "Add Date",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "changeDate",
      label: "Change Date",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "changeUser",
      label: "User",
      options: {
        filter: true,
        sort: false
      }
    }
  ];

  const options = {
    selectableRows: "multiple",
    selectableRowsHeader: true,
    selectToolbarPlacement: "replace",
    resizeableColumns: true,
    download: true,
    print: false,
    filterable: false,
    filter: true,
    search: true,
    pagination: true,
    viewColumns: true,
    filterType: "multiselect",
    responsive: "vertical",
    expandableRows: false,
    rowsPerPage: [10],
    rowsPerPageOptions: [10, 25, 50],
    jumpToPage: true,
    onRowSelectionChange: (curRowSelected, allRowsSelected) => {
      console.log("---RowSelect");
      console.log("Row Selected: ", curRowSelected);
      console.log("All Selected: ", allRowsSelected);
      setSelectedRowsToSubmit(allRowsSelected);
    },
    customToolbarSelect: () => {},
    onRowClick: handleRowClick,
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total rows Per Page"
      },
      body: {
        noMatch: isLoading ? (
          <PerksPageLoader isLoading />
        ) : (
          "No External PBM data to display"
        )
      }
    }
  };

  useEffect(() => {
    console.log("setting external Pbm List on load");
    setIsLoading(true);
    getExternalPBMList().then(function(axiosResult) {
      console.log("response.JSON:", {
        message: "Request received",
        data: axiosResult.data
      });
      setPbmListData(axiosResult.data);
      setRefreshListData(false);
      setIsLoading(false);
    });
    console.log("data from service call " + pbmListData);
    // check user role: full access or readonly
    determineUserAccess()
  }, [refreshListData]);

  // check user role: full access or readonly
  function determineUserAccess(){
    console.log("inside externalPBM datatable ", props.userRole.abilities["all-abilities"])
    const allAccessFiltered = props.userRole.abilities["all-abilities"].filter(elem => elem.includes(props.host+".operations.extpbm.all.access.grant"))
    if (allAccessFiltered.length > 0){
      setHasFullAccess(true);
    }
  }
  
  const handleAddNewPBMList = () => {
    setRefreshListData(true);
  };
  const handleAddButtonIconClick = () => {
    console.log("Selected pbm list to add ");
    setShowAddPBMListDialogPage(true);
    console.log("handleAddButton " + showAddPBMListDialogPage);
  };

  const handleClose = () => {
    console.log("Closing Popup");
    setShowAddPBMListDialogPage(false);
  };

  return isLoading ? (
    <PerksPageLoader show={isLoading} />
  ) : (
    <div>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <h1 className="perksHeader">External PBM List</h1>
        </Grid>
      </Grid>

      <Divider style={{ width: "100%" }} />

      <Grid container spacing={2} alignItems="center">
        <Grid
          item
          xs={2}
          justify="center"
          alignItems="center"
          style={{ marginLeft: "80%", marginTop: "12px" }}
        >
          {hasFullAccess && <AddButton
            className="addButtonText"
            clickHandler={handleAddButtonIconClick}
            name="Add PBM List"
          />}
        </Grid>
      </Grid>

      <br />

      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title="External PBM List"
          data={pbmListData}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
      {showAddPBMListDialogPage && (
        <AddExternalPBMList
          open={true}
          onClose={handleClose}
          onSubmit={handleAddNewPBMList}
        />
      )}
      {showDisplayPBMListDialogPage && (
        <DisplayExternalPBMList
          pbmRowData={currentlySelectedRow}
          onClose={handleDisplayClose}
        />
      )}

      {showEditPBMListDialogPage && (
        <EditExternalPBMList
          pbmRowData={currentlySelectedRow}
          onClose={handleEditClose}
          />
        )}

        {showProductsDialogPage && (
          <ProductsDialog
            pbmRowData={currentlySelectedRow}
            onClose={handleProductsClose}
          />
        )}
    </div>
  );
}

export default ExternalPBMListDatatable;

