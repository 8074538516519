import Input from "../components/Input"
import DatePicker from "../components/DatePicker"
import Button from "../components/Button"
import {SelectSingle,SelectMultiple} from "../components/SelectDropDown"

const Controls = {
    Input,
    DatePicker,
    Button,
    SelectSingle,
    SelectMultiple
}

export default Controls;    