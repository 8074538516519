import React, { useEffect, useState } from "react";
import { Link, Routes, Route, BrowserRouter as Router } from "react-router-dom";
import ExternalPBMDataTable from "../container/ExternalPBMDatatable";
import { useTheme } from "@material-ui/core/styles";
import ExternalPBMListDatatable from "../container/ExternalPBMListDatatable";
import { ToastContainer } from "react-toastify";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import WelcomePage from "../container/WelcomePage";
import { ValidateLogin } from "../container/ValidateLogin";
import NotAuthorized from "../container/NotAuthroized";
import Footer from "../container/Footer";

const PerksMainPage = () => {
  const theme = useTheme();

  const [showAppBar, setShowAppBar] = useState(false);
  const [userRole, setUserRole] = useState();
  const [host, setHost] = useState();

  function changeBackground(e) {
    e.target.style.background = "#FBF9F4";
  }

  function changeBackground2(e) {
    e.target.style.background = "#D9F6FA";
  }

  function showNavigationLinks(showValue) {
    console.log("inside show Navigation Links " + showValue);
    setShowAppBar(showValue);
  }

  const localUserRole =
  {
      "givenName": null,
      "familyName": null,
      "dgRoles": [], 
      "decodedIdToken": null,
      "abilities": {
          "actors": [
              "RxClaim_Advanced_User",
              "RxClaim_Intermediate_User"
          ],
          "all-abilities": [
              "RXDV1.operations.extpbm.all.access.grant",  
          ]
      },
      "id": null,
      "msid": [],
      "actors": [],
      "all-abilities": []
  }

  return (
    <div>
      {console.log("test userRole ", userRole, host)}
      <Router>
        <ToastContainer />
        {console.log("Main Page Render " + showAppBar)}
        {console.log("Main Page Windows location " + window.location.href.includes("notauthorized"))}
        {console.log("Main Page location " + window.location)}
        {console.log("Is local environment ?" +process.env.REACT_APP_API_ENV)}

        {((!window.location.href.includes("notauthorized") && showAppBar) || 
        (process.env.REACT_APP_API_ENV === 'local')) &&
          <AppBar elevation={0} position="static">
            <Grid container bgcolor="#D9F6FA" sx={{ height: '40px' }}>
              <Grid item xs={8}>
                <Link
                  to="/externalPBM"
                  className="home2"
                  onMouseOver={changeBackground}
                  onMouseOut={changeBackground2}
                >
                  External PBM Search
                </Link>

                <Link
                  to="/externalpbmlist"
                  className="home2"
                  onMouseOver={changeBackground}
                  onMouseOut={changeBackground2}
                >
                  External PBM List
                </Link>
              </Grid>
            </Grid>
          </AppBar>
        }
        <main>
          <Routes>
            <Route
              path="/externalpbm"
              element={
                <>
                {process.env.REACT_APP_API_ENV === 'local' ?
                  <ExternalPBMDataTable userRole={localUserRole} host="RXDV1"/>
                : <ExternalPBMDataTable userRole={userRole} host={host}/>
                }
                <Footer />
                </>
              }
            />
            <Route
              path="/externalpbmlist"
              element={
                <>
                {process.env.REACT_APP_API_ENV === 'local' ?
                  <ExternalPBMListDatatable userRole={localUserRole} host="RXDV1"/>
                : <ExternalPBMListDatatable userRole={userRole} host={host}/>
               }
                <Footer />
                </>
              }
            />
            {/* <Route
              path="/notauthorized"
              element={
                <>
                <NotAuthorized />
                <Footer />
                </>
              } 
            /> */}
            <Route 
              path="/:sessionId" 
              element={
                <>
                <WelcomePage />
                <ValidateLogin showNavigationLinks={showNavigationLinks} setUserRole={setUserRole} setHost={setHost}/>
                <Footer />
                </>
              } 
            />
            {/* <Route
              path="/:sessionId"
              element={<ValidateLogin showNavigationLinks={showNavigationLinks} setUserRole={setUserRole} setHost={setHost}/>} /> */}
            {/* <Route exact path="/" element={<WelcomePage />} /> */}


          </Routes>
        </main>
      </Router>
    </div>
  );
};
export default PerksMainPage;
