import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import { Typography, DialogTitle, makeStyles } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Controls from "../controls/Controls";
import MUIDataTable from "mui-datatables";
import getMuiTheme from "../assets/muitheme";
import { ThemeProvider } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { getExternalPbmsForList } from "../services/PerksServices";
import PerksPageLoader from "../components/PerksPageLoader";
import { formatDate } from "../utils/perksutil";

const columns = [
  {
    name: " ",
    options: {
      filter: false,
      sort: false,
      empty: true,
      customBodyRender: (value, tableMeta, updateValue) => {}
    }
  },
  {
    name: "xp2ExtPbmID",
    label: "Ext PBM ID",
    options: {
      filter: false,
      sort: true,
      sortThirdClickReset: true
    }
  },
  {
    name: "xp2ExtPBMName",
    label: "Ext PBM Name",
    options: {
      filter: false,
      sort: true,
      sortThirdClickReset: true
    }
  },
  {
    name: "fromDate",
    label: "From Date",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return value && formatDate(value);
      }
    }
  },
  {
    name: "thruDate",
    label: "Thru Date",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return value && formatDate(value);
      }
    }
  },
  {
    name: "status",
    label: "Status",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {

        return value && value === "A" ? "Active" : "Inactive";
      }
    }
  },
  {
    name: "addDate",
    label: "Add Date",
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: "changeDate",
    label: "Change Date",
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: "changeUser",
    label: "User",
    options: {
      filter: false,
      sort: false
    }
  }
];

const useStyles = makeStyles(theme => ({
  dialogWrapper: {
    padding: theme.spacing(1),
    position: "absolute",
    top: theme.spacing(5)
  },
  dialogTitle: {
    paddingRight: "10px",
    color: "#002677"
  }
}));

export default function DisplayExternalPBMList(props) {
  const classes = useStyles();
  const [displayPbmListData, setDisplayPbmListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshListData, setRefreshListData] = useState(false);
  const { onClose } = props;

  const initialFValues = {
    extPBMListId: props.pbmRowData[1].trim(),
    extPBMName: props.pbmRowData[2].trim()
  };

  const data = {
    pbmListId: ""
  };

  const options = {
    selectableRows: "multiple",
    selectableRowsHeader: true,
    selectToolbarPlacement: "replace",
    resizeableColumns: true,
    download: true,
    print: false,
    filterable: false,
    filter: true,
    search: true,
    pagination: true,
    viewColumns: true,
    filterType: "multiselect",
    responsive: "vertical",
    expandableRows: false,
    rowsPerPage: [10],
    rowsPerPageOptions: [10, 25, 50],
    jumpToPage: true,
    customToolbarSelect: () => {},
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total rows Per Page"
      },
      body: {
        noMatch: isLoading ? (
          <PerksPageLoader isLoading />
        ) : (
          "No External PBM List data to display"
        )
      }
    }
  };

  useEffect(() => {
    console.log("setting external Pbm List on load");
    data.pbmListId = initialFValues.extPBMListId;
    setIsLoading(true);
    getExternalPbmsForList(data).then(function(axiosResult) {
      console.log("response.JSON:", {
        message: "Request received",
        data: axiosResult.data
      });
      setDisplayPbmListData(axiosResult.data);
      setRefreshListData(false);
      setIsLoading(false);
    });
    console.log("data from service call " + displayPbmListData);
  }, [refreshListData]);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={true}
      PaperProps={{ variant: "elevation14" }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <br></br>
          <Typography variant="h6" component="div" style={{ flexGrow: 5 }}>
            External PBM List Detail
          </Typography>
        </div>
      </DialogTitle>
      <Divider style={{ width: "100%" }} />

      <Grid container>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Grid
              item
              xs={30}
              justify="left"
              alignItems="left"
              style={{ marginLeft: "10%", marginTop: "12px" }}
            >
              <br></br>
              <br></br>
              <Grid item xs={12}>
                <DialogContentText className={classes.dialogTitle}>
                  <div style={{ display: "flex", marginLeft: "28px" }}>
                    <br></br>
                    <Grid item xs={6}>
                      <Typography variant="h6" component="div" display="inline">
                        List ID
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        display="inline"
                      >
                        {initialFValues.extPBMListId}
                      </Typography>
                    </Grid>
                  </div>
                </DialogContentText>
              </Grid>

              <br></br>
              <Grid item xs={12}>
                <DialogContentText className={classes.dialogTitle}>
                  <div style={{ display: "flex", marginLeft: "28px" }}>
                    <br></br>

                    <Grid item xs={6}>
                      <Typography variant="h6" component="div" display="inline">
                        Description
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        display="inline"
                      >
                        {initialFValues.extPBMName}
                      </Typography>
                    </Grid>
                  </div>
                </DialogContentText>
              </Grid>

              <br></br>
              <br></br>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ width: "100%" }} />

        <div style={{ marginLeft: "50px" }}>
          <ThemeProvider theme={getMuiTheme()}>
            <br></br>
            <br></br>
            <MUIDataTable
              title="External PBM List"
              data={displayPbmListData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </div>

        <Grid container justify="center" alignItems="center">
          <Grid item xs={50} justify="center" alignItems="center">
            <br></br>
            <Controls.Button
              text="CANCEL"
              color="default"
              onClick={() => onClose()}
            />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
