import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, Tooltip, makeStyles } from "@material-ui/core";
import Controls from "../controls/Controls";
import { useForm, Form } from "../controls/useForm";
import { addPBMList } from "../services/PerksServices";
import { toast } from "react-toastify";
import PerksPageLoader from "../components/PerksPageLoader";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2, paddingRight: "10px", color: "#002677" }}
      {...other}
    >
      {children}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

export default function AddExternalPBMList(props) {
  const { onClose, open, onSubmit } = props;
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialFValues = {
    pbmListId: "",
    pbmListName: "",
    addUser: JSON.parse(sessionStorage.getItem("user-name")),
    addProgram: "Perks",
    changeUser: JSON.parse(sessionStorage.getItem("user-name")),
    changeProgram: "Perks"
  };

  const toasterConfig = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("pbmListId" in fieldValues)
      temp.pbmListId = fieldValues.pbmListId ? "" : "This field is required.";
    if ("pbmListName" in fieldValues)
      temp.pbmListName = fieldValues.pbmListName
        ? ""
        : "This field is required.";

    setErrors({
      ...temp
    });

    temp.pbmListId === "" && temp.pbmListName === ""
      ? setEnableSubmit(true)
      : setEnableSubmit(false);
    if (fieldValues == values) return Object.values(temp).every(x => x == "");
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm
  } = useForm(initialFValues, true, validate);

  const handleClose = () => {
    onClose();
  };

  const handleCreate = e => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      console.log("values object" + values);
      const extPBMListToSave = {
        pbmListId: values.pbmListId,
        pbmListName: values.pbmListName,
        addProgram: initialFValues.addProgram,
        addUser: initialFValues.addUser,
        changeUser: initialFValues.changeUser,
        changeProgram: initialFValues.changeProgram
      };
      console.log("About to save pbm list " + extPBMListToSave.pbmListId);
      addPBMList(extPBMListToSave).then(function(axiosResult) {
        console.log("axios result from Add External pbm list " + axiosResult);
        if (axiosResult !== null && axiosResult.data !== null) {
          console.log("Add PBM List response.JSON:", {
            message: "Post successful",
            data: axiosResult
          });
          setLoading(false);
          handleClose();
          onSubmit();
          toast.success(
            `External PBM List ${extPBMListToSave.pbmListId.toUpperCase()} Added Successfully`,
            toasterConfig
          );
        } else {
          setLoading(false);
          toast.error(
            `Error while saving new External PBM List ${extPBMListToSave.pbmListId.toUpperCase()}`,
            toasterConfig
          );
        }
      });
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Add External PBM List
        </BootstrapDialogTitle>
        <form onSubmit={handleCreate} id="externPBMListForm">
          <DialogContent dividers>
            <Grid container justifyContent="flex-start" spacing={2}>
              <Grid item xs={10} md={8}>
                <Tooltip
                  title="Enter the External PBM List"
                  placement="top"
                  arrow
                >
                  <Controls.Input
                    name="pbmListId"
                    fullWidth
                    label="External PBM List*"
                    inputProps={{
                      maxLength: 10,
                      style: { textTransform: "uppercase" }
                    }}
                    value={values.pbmListId}
                    onChange={handleInputChange}
                    error={errors.pbmListId}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={10} md={8}>
                <Tooltip
                  title="Enter the External PBM List Description"
                  placement="top"
                  arrow
                >
                  <Controls.Input
                    fullWidth
                    name="pbmListName"
                    label="External PBM List Description*"
                    inputProps={{
                      maxLength: 25
                    }}
                    value={values.pbmListName}
                    onChange={handleInputChange}
                    error={errors.pbmListName}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Controls.Button
              type="submit"
              variant="contained"
              autoFocus
              disabled={!enableSubmit}
              text="SUBMIT"
            />
            <Controls.Button
              text="CANCEL"
              color="default"
              onClick={() => onClose()}
            />

            <PerksPageLoader show={loading} />
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}
