// This filter component will have the option to search and select multiple values
import React from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

// This function is created to handle single select
export function SelectSingle(props) {
    const { placeholder, name, data, onChange, value, disabled, clearableIcon, error = null } = props
  
    return (
      <Autocomplete
        // disablePortal
        disabled={disabled}
        options={data}
        onChange={onChange}
        value={value || null}
        disableClearable={clearableIcon}
        getOptionLabel={(option) => typeof option === "string" ? option : option.label || "" }
        isOptionEqualToValue={(option, value) => option.label === value.label}
        renderInput={(params) => {
          return (
            <TextField {...params} label={placeholder} name={name} variant="outlined"
              sx={{
                ".MuiAutocomplete-endAdornment": {
                  top: 'auto'
                }
              }}
  
            />
  
          )
        }
        }
  
  
  
  
      />
    );
  
  }

  export function SelectMultiple(props) {

    const { label, name, value, dropDownOptions, onChange, error = null } = props
  
    return (
  
  
      <Autocomplete
        multiple
        id="tags-standard"
        options={dropDownOptions}
        getOptionLabel={(option) => option.label}
        popupIcon={<FilterAltOutlinedIcon />}
        // style={{ height: 15 }}
        onChange={onChange}
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            // label="Reject Codes"
            placeholder={label}
            size="small"
            sx={{
              ".MuiAutocomplete-endAdornment": {
                top: 'auto'
              }
            }}
          />
        )}
      />
  
  
    )
  }