import React, { useEffect, useState } from "react";
import { getExternalPBMs } from "../services/PerksServices";
import MUIDataTable from "mui-datatables";
import getMuiTheme from "../assets/muitheme";
import { ThemeProvider } from "@mui/material/styles";
import PerksPageLoader from "../components/PerksPageLoader";
import settingsGear from "../assets/UHG_Operations_Navy.png";
import AddPBM from "./AddPBM";
import { Grid } from "@mui/material";
import AddButton from "../components/AddButton";
import Divider from "@mui/material/Divider";
import PerksTooltip from "../components/PerksTooltip.js";
import EditPBM from "./EditPBM";
import ViewPBM from "./ViewPBM";
import { formatDate } from "../utils/perksutil";

function ExternalPBMDatatable(props) {
  const [pbmData, setPbmData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddPBMDialogPage, setShowAddPBMDialogPage] = useState(false);
  const [showEditPBMDialogPage, setShowEditPBMDialogPage] = useState(false);
  const [showViewPBMDialogPage, setShowViewPBMDialogPage] = useState(false);
  const [currentlySelectedRow, setCurrentlySelectedRow] = useState([]);
  const [selectedRowsToSubmit, setSelectedRowsToSubmit] = useState([]);
  const [refreshTableData, setRefreshTableData] = useState(false);

  const [hasFullAccess, setHasFullAccess] = useState(false);
  
  const columns = [
    {
      name: " ",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <PerksTooltip
              rowData={tableMeta.rowData}
              handleEditClick={() => {
                setCurrentlySelectedRow(tableMeta.rowData);
                handleEditClick();
              }}
              handleViewClick={handleViewClick}
              hasFullAccess={hasFullAccess}
            />
          );
        }
      }
    },
    {
      name: "extPbmId",
      label: "Ext PBM ID",
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "extPbmName",
      label: "Ext PBM Name",
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "extPbmUuid",
      label: "Ext PBM UUID",
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "fromDate",
      label: "From Date",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value && formatDate(value);
        }
      }
    },
    {
      name: "thruDate",
      label: "Thru Date",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value && formatDate(value);
        }
      }
    },

    {
      name: "addDate",
      label: "Create Date",
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "changeDate",
      label: "Modified Date",
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "changeUser",
      label: "User",
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value && value === "A" ? "Active" : "Inactive";
        }
      }
    },
    {
      name: "xp3BinNumber",
      label: "Bin",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "xp3ControlNumber",
      label: "PCN",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "xp3GroupNumber",
      label: "Group",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "xp3DiscountCard",
      label: "Ext Disc CHID",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    }
  ];

  const options = {
    selectableRows: "multiple",
    selectableRowsHeader: true,
    selectToolbarPlacement: "replace",
    resizeableColumns: true,
    download: true,
    print: false,
    filterable: false,
    filter: true,
    search: true,
    pagination: true,
    viewColumns: true,
    filterType: "multiselect",
    responsive: "vertical",
    expandableRows: false,
    rowsPerPage: [10],
    rowsPerPageOptions: [10, 25, 50],
    jumpToPage: true,
    onRowSelectionChange: (curRowSelected, allRowsSelected) => {
      console.log("---RowSelect");
      console.log("Row Selected: ", curRowSelected);
      console.log("All Selected: ", allRowsSelected);
      setSelectedRowsToSubmit(allRowsSelected);
    },
    customToolbarSelect: () => {},
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total rows Per Page"
      },
      body: {
        noMatch: isLoading ? (
          <PerksPageLoader isLoading />
        ) : (
          "No External PBM data to display"
        )
      }
    }
  };

  useEffect(() => {
    console.log("setting external Pbms on load");
    setIsLoading(true);
    getExternalPBMs().then(function(axiosResult) {
      console.log("response.JSON:", {
        message: "Request received",
        data: axiosResult.data
      });
      setPbmData(axiosResult.data);
      setRefreshTableData(false);
      setIsLoading(false);
    });
    console.log("data from service call " + pbmData);
    // check user role: full access or readonly
    determineUserAccess()
  }, [refreshTableData]);

  // check user role: full access or readonly
  function determineUserAccess(){
    console.log("inside externalPBM datatable ", props.userRole.abilities["all-abilities"])
    console.log("inside externalPBM datatable ", props.host)
    const allAccessFiltered = props.userRole.abilities["all-abilities"].filter(elem => elem.includes(props.host+".operations.extpbm.all.access.grant"))
    if (allAccessFiltered.length > 0){
      setHasFullAccess(true);
    }
  }

  const handleAddNewPBM = () => {
    setRefreshTableData(true);
  };
  const handleAddButtonIconClick = () => {
    setShowAddPBMDialogPage(true);

    console.log("handle add pbm dialog Button " + showAddPBMDialogPage);
  };

  const handleEditPBM = () => {
    setRefreshTableData(true);
  };

  const handleEditClick = rowData => {
    console.log("handle edit click " + rowData);

    setShowEditPBMDialogPage(true);
  };

  const handleViewClick = rowData => {
    console.log("handle view click " + rowData);
    setShowViewPBMDialogPage(true);
    setCurrentlySelectedRow(rowData);
  };

  const handleClose = () => {
    setShowAddPBMDialogPage(false);
  };

  const handlePBMViewClose = () => {
    setShowViewPBMDialogPage(false);
    setCurrentlySelectedRow(null);
  };

  const handleCloseEdit = () => {
    setShowEditPBMDialogPage(false);
  };

  return isLoading ? (
    <PerksPageLoader show={isLoading} />
  ) : (
    <div class="disp-header-muiTable">
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <h1 className="perksHeader">External PBM</h1>
        </Grid>
      </Grid>

      <Divider style={{ width: "100%" }} />

      <Grid container alignItems="center">
        <Grid
          item
          xs={2}
          justify="center"
          alignItems="center"
          style={{ marginLeft: "80%", marginTop: "12px" }}
        >
          {hasFullAccess && <AddButton
            className="addButtonText"
            clickHandler={handleAddButtonIconClick}
            name="Add PBM"
          />}
        </Grid>
      </Grid>

      <br />

      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title="External PBM"
          data={pbmData}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
      {showAddPBMDialogPage && (
        <AddPBM onClose={handleClose} onSubmit={handleAddNewPBM} />
      )}
      {showEditPBMDialogPage && (
        <EditPBM
          onClose={handleCloseEdit}
          data={currentlySelectedRow}
          onSubmit={handleEditPBM}
        />
      )}
      {showViewPBMDialogPage && (
        <ViewPBM
          pbmRowData={currentlySelectedRow}
          onClose={handlePBMViewClose}
        />
      )}

      {showViewPBMDialogPage && (
        <ViewPBM
          pbmRowData={currentlySelectedRow}
          onClose={handlePBMViewClose}
        />
      )}
    </div>
  );
}

export default ExternalPBMDatatable;
