import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Typography, DialogTitle, makeStyles } from "@material-ui/core";
import { Grid, Box, Paper } from "@material-ui/core";
import Controls from "../controls/Controls";
import MUIDataTable from "mui-datatables";
import getMuiTheme from "../assets/muitheme";
import { ThemeProvider } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { getExternalPbmsForList } from "../services/PerksServices";
import PerksPageLoader from "../components/PerksPageLoader";
import AddButton from "../components/AddButton";
import SelectExternalPBM from "./SelectExternalPBM";
import PerksTooltip from "../components/PerksTooltip.js";
import { useForm, Form } from "../controls/useForm";
import { editExternalPbmList } from "../services/PerksServices";
import { inactivateexternalpbmlink } from "../services/PerksServices";
import { toast } from "react-toastify";
import EditPBMToList from "./EditPBMToList";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import settingsGear from "../assets/UHG_Operations_Navy.png";
import { formatDate } from "../utils/perksutil";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#316BBE",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: 10,
    margin: 10
  }
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2, paddingRight: "10px", color: "#002677" }}
      {...other}
    >
      {children}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

const useStyles = makeStyles(theme => ({
  dialogWrapper: {
    padding: theme.spacing(1),
    position: "absolute",
    top: theme.spacing(5)
  },
  dialogTitle: {
    paddingRight: "10px",
    color: "#002677"
  }
}));

export default function EditExternalPBMList(props) {
  const classes = useStyles();
  const [displayPbmListData, setDisplayPbmListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshListData, setRefreshListData] = useState(false);
  const [showAddPBMDialogPage, setShowAddPBMDialogPage] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(true);
  const [loading, setLoading] = useState(false);
  const { onClose, open, onSubmit, refresh } = props;
  const [currentlySelectedRow, setCurrentlySelectedRow] = useState([]);
  const [showEditPBMListDialogPage, setShowEditPBMListDialogPage] = useState(
    false
  );
  const toasterConfig = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  };

  const columns = [
    {
      name: " ",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <LightTooltip
              arrow
              sx={{ "& .MuiTooltip-arrow": { color: "white" } }}
              placement="right"
              title={
                <>
                  <div
                    onClick={() => handleEditClick(tableMeta.rowData)}
                    onMouseOver={changeBGColor}
                    onMouseLeave={revertBGColor}
                    style={{
                      fontSize: "16px",
                      fontFamily: "OptumSansBold",
                      cursor: "pointer",
                      marginLeft: "2px",
                      marginRight: "2px"
                    }}
                  >
                    <EditIcon style={{ height: "0.8em", width: "0.8em" }} />{" "}
                    &nbsp;&nbsp;EDIT
                  </div>

                  <br></br>

                  <div
                    onClick={() => handleInactivateClick(tableMeta.rowData)}
                    onMouseOver={changeBGColor}
                    onMouseLeave={revertBGColor}
                    style={{
                      fontSize: "16px",
                      fontFamily: "OptumSansBold",
                      cursor: "pointer",
                      marginLeft: "2px",
                      marginRight: "2px"
                    }}
                  >
                    <VisibilityOffIcon
                      style={{ height: "0.8em", width: "0.8em" }}
                    />{" "}
                    &nbsp;&nbsp;INACTIVATE&nbsp;&nbsp;
                  </div>
                </>
              }
            >
              <img
                width="30px"
                height="27px"
                src={settingsGear}
                alt="settings gear"
              />
            </LightTooltip>
          );
        }
      }
    },
    {
      name: "xp2ExtPbmID",
      label: "Ext PBM ID",
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "xp2ExtPBMName",
      label: "Ext PBM Name",
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "fromDate",
      label: "From Date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value && formatDate(value);
        }
      }
    },

    {
      name: "thruDate",
      label: "Thru Date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value && formatDate(value);
        }
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value && value === "A" ? "Active" : "Inactive";
        }
      }
    },
    {
      name: "addDate",
      label: "Add Date",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "changeDate",
      label: "Change Date",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "changeUser",
      label: "User",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "xp4SeqNbr",
      label: "Sequence Number",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    }
  ];

  const handleEditClick = rowData => {
    console.log("handle edit click " + rowData);
    setShowEditPBMListDialogPage(true);
    setCurrentlySelectedRow(rowData);
  };

  const handleViewClick = rowData => {
    console.log("handle view click " + rowData);
  };

  const changeBGColor = e => {
    e.target.style.background = "rgb(245,245,245)";
  };

  const revertBGColor = e => {
    e.target.style.background = "white";
  };

  const initialFValues = {
    extPBMListId: props.pbmRowData[1].trim(),
    extPBMListName: props.pbmRowData[2].trim(),
    addUser: props.pbmRowData[5].trim(),
    addProgram: props.pbmRowData[6],
    changeUser: "PerksUser",
    changeProgram: "Perks"
  };

  const data = {
    pbmListId: ""
  };

  const handleChange = e => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      console.log("values object" + values);
      const extPBMListToUpdate = {
        pbmListId: values.extPBMListId,
        pbmListName: values.extPBMListName,
        addUser: values.addUser,
        addProgram: values.addProgram,
        changeUser: values.changeUser,
        changeProgram: values.changeProgram
      };

      console.log("About to Edit PBM List" + extPBMListToUpdate.fromDate);
      editExternalPbmList(extPBMListToUpdate).then(function(axiosResult) {
        console.log("axios result from Edit External Pbm " + axiosResult);
        if (axiosResult !== null && axiosResult.data !== null) {
          console.log("Update PBM response.JSON:", {
            message: "Post successful",
            data: axiosResult
          });
          setLoading(false);
          handleClose();
          //onSubmit();
          toast.success(
            `External PBM List ${extPBMListToUpdate.pbmListId} updated Successfully`,
            toasterConfig
          );
        } else {
          setLoading(false);
          toast.error("Error while updating External PBM List", toasterConfig);
        }
      });
    }
  };

  const handleInactivateClick = rowData => {
    console.log("handle interactive click " + rowData);
    //e.preventDefault();

    setLoading(true);
    console.log("values object" + values);
    const extPBMListDetail = {
      xp1ExtPbmList: initialFValues.extPBMListId,
      xp2ExtPbmID: rowData[1],
      xp4SeqNbr: "99999",
      fromDate: rowData[3],
      thruDate: rowData[4],
      status: "I",
      addUser: JSON.parse(sessionStorage.getItem("user-name")),
      addProgram: "Perks",
      changeUser: JSON.parse(sessionStorage.getItem("user-name")),
      changeProgram: "Perks"
    };
    console.log("Here");
    console.log(extPBMListDetail);
    console.log(
      "About to Inactive PBM List Detail" + extPBMListDetail.xp2ExtPbmID
    );
    inactivateexternalpbmlink(extPBMListDetail).then(function(axiosResult) {
      console.log("axios result from Edit External Pbm " + axiosResult);
      if (axiosResult !== null && axiosResult.data !== null) {
        console.log("Update PBM response.JSON:", {
          message: "Post successful",
          data: axiosResult
        });
        setLoading(false);
        handleClose();
        //onSubmit();
        toast.success(
          `External PBM Id ${extPBMListDetail.xp2ExtPbmID} Inactivated Successfully`,
          toasterConfig
        );
      } else {
        setLoading(false);
        toast.error("Error while inactivating External PBM Id", toasterConfig);
      }
    });
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("extPBMListName" in fieldValues)
      temp.extPBMListName = fieldValues.extPBMListName
        ? ""
        : "This field is required.";

    setErrors({
      ...temp
    });

    temp.extPBMListName == "" ? setEnableSubmit(true) : setEnableSubmit(false);

    if (fieldValues == values) return Object.values(temp).every(x => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const options = {
    selectableRows: "multiple",
    selectableRowsHeader: true,
    selectToolbarPlacement: "replace",
    resizeableColumns: true,
    download: true,
    print: false,
    filterable: false,
    filter: true,
    search: true,
    pagination: true,
    viewColumns: true,
    filterType: "multiselect",
    responsive: "vertical",
    expandableRows: false,
    rowsPerPage: [10],
    rowsPerPageOptions: [10, 25, 50],
    jumpToPage: true,
    customToolbarSelect: () => {},
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total rows Per Page"
      },
      body: {
        noMatch: isLoading ? (
          <PerksPageLoader isLoading />
        ) : (
          "No External PBM List data to display"
        )
      }
    }
  };

  useEffect(() => {
    console.log("setting external Pbm List on load");
    data.pbmListId = initialFValues.extPBMListId;
    setIsLoading(true);
    getExternalPbmsForList(data).then(function(axiosResult) {
      console.log("response.JSON:", {
        message: "Request received",
        data: axiosResult.data
      });
      setDisplayPbmListData(axiosResult.data);
      setRefreshListData(false);
      setIsLoading(false);
    });
    console.log("data from service call " + displayPbmListData);
  }, [refreshListData]);

  const handleEditPBM = () => {
    setRefreshListData(true);
  };

  const handleAddButtonIconClick = () => {
    setShowAddPBMDialogPage(true);
    console.log("handle edit pbm list dialog Button " + showAddPBMDialogPage);
  };

  const handleClose = () => {
    setShowAddPBMDialogPage(false);
    // setRefreshListData(true);
  };

  const handleAddPBM = () => {
    setShowAddPBMDialogPage(false);
    setRefreshListData(true);
  };

  const handleCloseEdit = () => {
    setShowEditPBMListDialogPage(false);
  };

  return isLoading ? (
    <PerksPageLoader show={isLoading} />
  ) : (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={true}
      PaperProps={{ variant: "elevation14" }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 5 }}>
            External PBM List Detail
          </Typography>
        </div>
      </DialogTitle>

      <Divider style={{ width: "100%" }} />

      <Form onSubmit={handleChange} id="externPBMListForm">
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <Grid
              item
              justify="left"
              alignItems="left"
              style={{
                marginLeft: "7%",
                marginTop: "10px",
                marginBottom: "2px"
              }}
            >
              <DialogContentText className={classes.dialogTitle}>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <Grid item xs={12}>
                      <Controls.Input
                        name="extPBMListId"
                        label="Ext PBM List Id"
                        value={initialFValues.extPBMListId}
                        disabled
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid item xs={12}>
                      <Controls.Input
                        name="extPBMListName"
                        label="Ext PBM List Name*"
                        defaultValue={initialFValues.extPBMListName}
                        inputProps={{
                          maxLength: 25
                        }}
                        onChange={handleInputChange}
                        error={errors.extPBMListName}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContentText>

              <Grid
                container
                spacing={1}
                style={{
                  marginLeft: "55%",
                  marginTop: "2px",
                  marginBottom: "2px"
                }}
              >
                <DialogActions>
                  <Grid item xs={50}>
                    <Controls.Button
                      text="UPDATE"
                      color="default"
                      type="Submit"
                      disabled={!enableSubmit}
                    />
                  </Grid>

                  <Grid item xs={50}>
                    <Controls.Button
                      text="CANCEL"
                      color="default"
                      onClick={() => onClose()}
                    />
                  </Grid>
                  <PerksPageLoader show={loading} />
                </DialogActions>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>

      <Divider style={{ width: "100%" }} />

      <Grid container alignItems="center">
        <Grid
          item
          xs={2}
          justify="center"
          alignItems="center"
          style={{
            marginLeft: "80%",
            marginTop: "5px",
            marginBottom: "20px"
          }}
        >
          <AddButton
            className="addButtonText"
            clickHandler={handleAddButtonIconClick}
            name="Add PBM"
          />
        </Grid>
      </Grid>

      <div style={{ marginLeft: "40px" }}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title="External PBM List"
            data={displayPbmListData}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
        {showAddPBMDialogPage && (
          <SelectExternalPBM
            listId={initialFValues.extPBMListId}
            handleClose={handleClose}
            refresh={handleAddPBM}
            
          />
        )}
        {showEditPBMListDialogPage && (
          <EditPBMToList
            pbmRowData={currentlySelectedRow}
            addListId={initialFValues.extPBMListId}
            onClose={handleCloseEdit}
            refresh={handleEditPBM}
          />
        )}
      </div>

      <br></br>
    </Dialog>
  );
}
