import React, { useState, useEffect } from "react";
import { Grid, Box, Paper } from "@material-ui/core";
import Controls from "../controls/Controls";
import { useForm, Form } from "../controls/useForm";
import { useNavigate } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Typography, DialogTitle, makeStyles } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { toast } from "react-toastify";
import MUIDataTable from "mui-datatables";
import getMuiTheme from "../assets/muitheme";
import { ThemeProvider } from "@mui/material/styles";
import PerksPageLoader from "../components/PerksPageLoader";
import settingsGear from "../assets/UHG_Operations_Navy.png";
import AddPBMToList from "./AddPBMToList";
import AddButton from "../components/AddButton";
import Divider from "@mui/material/Divider";
import { getArrayDataFromIndices } from "../utils/perksutil";
import { getExternalPbmIDs } from "../services/PerksServices";
import Button from "@mui/material/Button";


const useStyles = makeStyles(theme => ({
  dialogWrapper: {
    padding: theme.spacing(1),
    position: "absolute",
    top: theme.spacing(5)
  },
  dialogTitle: {
    paddingRight: "10px",
    color: "#002677"
  },
  leftBtn: {
    margin: theme.spacing(1.5),
    color: 'white',
    backgroundColor: '#002677',
    left: "66%",
},
rightBtn: {
  color: 'white',
  backgroundColor: '#002677',
  left: "66%",
}
}));
function SelectExternalPBM(props) {
  const [pbmData, setPbmData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddPBMDialogPage, setShowAddPBMDialogPage] = useState(false);
  const [currentlySelectedRow, setCurrentlySelectedRow] = useState([]);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const { onClose, open, onSubmit,refresh } = props;
  const [pbmListData, setPbmListData] = useState([]);
  const [refreshListData, setRefreshListData] = useState(false);
  const [setOpen] = useState(false);
  const [selectedRowsToSubmit, setSelectedRowsToSubmit] = useState([]);
  const [externalPbmData, setexternalPbmData] = useState([]);
  const [
    showDisplayPBMListDialogPage,
    setShowDisplayPBMListDialogPage
  ] = useState(false);
  const [showAddPBMListDialogPage, setShowAddPBMListDialogPage] = useState(
    false
  );
  const classes = useStyles();

  const handleRowClick = (rowData, rowMeta) => {
    console.log("Selected Row Data");
    console.log(rowData, rowMeta);
  };

  const handleDisplayClose = () => {
    setShowDisplayPBMListDialogPage(false);
  };

  const navigateBack = useNavigate();
  function ClickCancel() {
    navigateBack(0);
  }

  const columns = [
    {
      name: " ",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {}
      }
    },
    {
      name: "pbmListId",
      label: "Ext PBM ID",
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "pbmName",
      label: "Ext PBM Name",
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true
      }
    }
  ];
  const listId = props.listId;
  console.log(listId);

  const options = {
    selectableRows: "multiple",
    selectableRowsHeader: true,
    selectToolbarPlacement: "none",
    resizeableColumns: true,
    download: false,
    print: false,
    filterable: false,
    filter: false,
    search: true,
    pagination: true,
    viewColumns: false,
    filterType: "multiselect",
    responsive: "vertical",
    expandableRows: false,
    rowsPerPage: [10],
    rowsPerPageOptions: [10, 25, 50],
    jumpToPage: true,
    customToolbarSelect: () => {},
    onRowsSelect: (curRowSelected, allRowsSelected) => {
      console.log("---RowSelect");
      console.log("Row Selected: ", curRowSelected);
      console.log("All Selected: ", allRowsSelected);
      setSelectedRowsToSubmit(allRowsSelected);
      console.log(curRowSelected.length);
      if (allRowsSelected.length) {
        setEnableSubmit(true);
      } else {
        setEnableSubmit(false);
      }
    },

    onRowClick: handleRowClick,

    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total rows Per Page"
      },
      body: {
        noMatch: isLoading ? (
          <PerksPageLoader isLoading />
        ) : (
          "No External PBM data to display"
        )
      }
    }
  };

  useEffect(() => {
    console.log("setting external Pbms on load to add to pbm list");
    setIsLoading(true);
    getExternalPbmIDs().then(function(axiosResult) {
      console.log("response.JSON:", {
        message: "Request received",
        data: axiosResult.data
      });

      setPbmData(axiosResult.data);
      setIsLoading(false);
      console.log(axiosResult.data);
    });
    console.log("data from service call " + pbmData);
    console.log(pbmData);
  }, []);

  // const handleAddButtonIconClick = () => {
  //  setShowAddPBMDialogPage(true);
  //  console.log("handle add pbm dialog Button " + showAddPBMDialogPage);
  //};
  const handleAddNewPBMList = () => {
    setRefreshListData(true);
  };

  const handleAddButtonIconClick = () => {
    console.log("Selected pbm list to add ");
    console.log(selectedRowsToSubmit);
    console.log(getArrayDataFromIndices(pbmData, selectedRowsToSubmit));
    setexternalPbmData(getArrayDataFromIndices(pbmData, selectedRowsToSubmit));
    console.log(pbmData);
    setShowAddPBMListDialogPage(true);
    console.log("handleAddButton " + showAddPBMListDialogPage);
  };

  const handleClose = () => {
    console.log("Closing Popup");
    setShowAddPBMListDialogPage(false);
  };

  const handleAddPBM = () => {
    refresh();
    setShowAddPBMListDialogPage(false);
  };

  return isLoading ? (
    <PerksPageLoader show={isLoading} />
  ) : (
    <Dialog
      fullWidth
      maxWidth="md"
      open={true}
      PaperProps={{ variant: "elevation14" }}
    >
      <Paper>
        <div>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              
          <DialogTitle className={classes.dialogTitle}>
          <div style={{ display: "flex" }}>
          <br></br>
          <Typography variant="h6" component="div" style={{ flexGrow: 5 }}>
            Add Selected PBM
          </Typography>
        </div>
            
              </DialogTitle>
            </Grid>
          </Grid>

          <Divider style={{ width: "100%" }} />
          <Grid container alignItems="center">
            <Grid
              item
              xs={12}
              justify="right"
              alignItems="right"
              // style={{ marginLeft: "68%", marginTop: "8px" }}
              style={{ marginTop: "8px" }}
            >
              
              <Button className={classes.leftBtn}
               style={{maxWidth: '190px', maxHeight: '150px', minWidth: '90px', minHeight: '40px'}}
                variant="contained"               
                disabled={!enableSubmit}
                onClick={handleAddButtonIconClick}
              >
                <b>Add Selected PBM</b>
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button className={classes.rightBtn}
                variant="contained"
                style={{maxWidth: '150px', maxHeight: '150px', minWidth: '90px', minHeight: '40px'}}
                onClick={() => props.handleClose()}
              >
                <b>Cancel</b>
              </Button>
            </Grid>
          </Grid>

          <br />
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title="External PBM"
              data={pbmData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
          {showAddPBMListDialogPage && (
            <AddPBMToList
              pbmData={externalPbmData}
              addListId={listId}
              onClose={handleClose}
              refresh={handleAddPBM}
            />
          )}
        </div>
      </Paper>
    </Dialog>
  );
}

export default SelectExternalPBM;
