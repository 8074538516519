import React, { useState, useEffect } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { makeStyles } from "@material-ui/core";
import Button from "@mui/material/Button";

const useStyles = makeStyles(theme => ({
  icon: {
    color: "#316BBE",
    alignItems: "center",
    display: "flex",
    backgroundColor: "transparent"
  }
}));

function AddButton(props) {
  const classes = useStyles();

  return (
    <div>
      <Button
        onClick={() => {
          props.clickHandler();
        }}
        style={{ backgroundColor: "transparent", color: "#316BBE" }}
      >
        <AddCircleIcon /> <span className="addButtonText">{props.name}</span>
      </Button>
    </div>
  );
}

export default AddButton;
