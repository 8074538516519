import React, { useEffect, useState } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import settingsGear from "../assets/UHG_Operations_Navy.png";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import ListIcon from '@mui/icons-material/List';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#316BBE",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: 10,
    margin: 10
  }
}));

const PerksTooltip = props => {
  return (
    <LightTooltip
      arrow
      sx={{ "& .MuiTooltip-arrow": { color: "white" } }}
      placement="right"
      title={
        <>
          <div>
            {props.hasFullAccess && <Button
              id={props.rowData}
              variant="none"
              onClick={() => props.handleEditClick(props.rowData)}
              startIcon={<EditIcon />}
            >
              Edit
            </Button>}
          </div>
          {props.handleInactivateClick !== undefined ? (
            <div>
              <Button
                variant="none"
                onClick={() => props.handleInactivateClick(props.rowData)}
                startIcon={<VisibilityOffIcon />}
              >
                Inactivate
              </Button>
            </div>
          ) : (
            <div>
              <Button
                variant="none"
                onClick={() => props.handleViewClick(props.rowData)}
                startIcon={<VisibilityOutlinedIcon />}
              >
                View
              </Button>
            </div>
          )}
          {props.handleProductsClick !== undefined && (
          <div>
            <Button
              id={props.rowData}
              variant="none"
              onClick={() => props.handleProductsClick(props.rowData)}
              startIcon={<ListIcon />}
              // disabled
            >
              Products
            </Button>
          </div>
            )}
        </>
      }
    >
      <img width="30px" height="27px" src={settingsGear} alt="settings gear" />
    </LightTooltip>
  );
};
export default PerksTooltip;
