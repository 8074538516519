import React, { useState, useEffect } from "react";
import { Grid, Box, Paper } from "@material-ui/core";
import Controls from "../controls/Controls";
import { useForm, Form } from "../controls/useForm";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Typography, DialogTitle, makeStyles } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { toast } from "react-toastify";
import DialogActions from "@mui/material/DialogActions";
import { addExternalPbmLink } from "../services/PerksServices";
import PerksPageLoader from "../components/PerksPageLoader";

const useStyles = makeStyles(theme => ({
  dialogWrapper: {
    padding: theme.spacing(1),
    position: "absolute",
    top: theme.spacing(5)
  },
  dialogTitle: {
    paddingRight: "10px",
    color: "#002677"
  }
}));

export default function AddPBMToList(props) {
  const classes = useStyles();
  const { addOrEdit, recordForEdit } = props;
  const { onClose, open, onSubmit,refresh } = props;
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const pbms = props.pbmData;
  const pbmListId = props.addListId;

  const initialFValues = {
    extPBMId: pbms[0].pbmListId,
    extPBMName: pbms[0].pbmName,
    fromDate: new Date(),
    thruDate: new Date()
  };

  const handleClose = () => {
    console.log("Closing Popup");
    onClose();
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("extPBMId" in fieldValues)
      temp.extPBMId = fieldValues.extPBMId ? "" : "This field is required.";
    if ("extPBMName" in fieldValues)
      temp.extPBMName = fieldValues.extPBMName ? "" : "This field is required.";
    if ("fromDate" in fieldValues)
      temp.fromDate = fieldValues.fromDate ? "" : "This field is required.";
    if ("thruDate" in fieldValues)
      temp.thruDate = fieldValues.thruDate ? "" : "This field is required.";
    if (values.fromDate > values.thruDate)
      temp.thruDate = "Thru Date cannot be less than From date.";

    setErrors({
      ...temp
    });

    temp.extPBMId == "" && temp.extPBMName == ""
      ? setEnableSubmit(true)
      : setEnableSubmit(false);

    if (fieldValues == values) return Object.values(temp).every(x => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleSubmit = e => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      console.log("values object" + values);
      const extPBMListToSave = {
        xp1ExtPbmList: pbmListId,
        xp2ExtPbmID: values.extPBMId,
        fromDate: values.fromDate,
        thruDate: values.thruDate,
        user: JSON.parse(sessionStorage.getItem("user-name"))
      };

      console.log(extPBMListToSave);
      addExternalPbmLink(extPBMListToSave).then(function(axiosResult) {
        console.log("axios result from Add External Pbm List " + axiosResult);
        if (axiosResult !== null && axiosResult.data !== null) {
          console.log("Add PBM List response.JSON:", {
            message: "Post successful",
            data: axiosResult
          });
          setLoading(false);
          refresh();

          toast.success(
            `External PBM Id ${extPBMListToSave.xp2ExtPbmID} Added Successfully`,
            toasterConfig
          );
        } else {
          setLoading(false);
          toast.error(
            `Error while saving new External PBM Id ${extPBMListToSave.xp2ExtPbmID} `,
            toasterConfig
          );
        }
      });
    }
  };

  const toasterConfig = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit
      });
  }, [recordForEdit]);

  return (
    <Dialog
      maxWidth="sm"
      open={true}
      //PaperProps={{ variant: "elevation12" }}
    >
      <Paper>
        <Form onSubmit={handleSubmit}>
          <DialogTitle className={classes.dialogTitle}>
            <div style={{ display: "flex" }}>
              <br></br>
              <Typography variant="h6" component="div" style={{ flexGrow: 5 }}>
                Add External PBM
              </Typography>
            </div>
          </DialogTitle>

          <DialogContent dividers>
            <Grid container justifyContent="flex-start" spacing={1}>
              <Grid item xs={12} md={10}>
                <Typography
                  className="label1"
                  component="div"
                  style={{ flexGrow: 5 }}
                >
                  &nbsp;&nbsp;&nbsp;<b>PBM ID</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {initialFValues.extPBMId}
                </Typography>
              </Grid>

              <Grid item xs={12} md={10}>
                <Typography
                  className="label1"
                  component="div"
                  style={{ flexGrow: 5 }}
                >
                  &nbsp;&nbsp;&nbsp;<b>PBM Name</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {initialFValues.extPBMName}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogContent dividers>
            <Grid container justifyContent="flex-start" spacing={1}>
              <Grid item xs={12} md={10}>
                <Controls.DatePicker
                  name="fromDate"
                  label="From Date*"
                  value={values.fromDate}
                  onChange={handleInputChange}
                  error={errors.fromDate}
                />
              </Grid>
              <Grid item xs={12} md={10}>
                <Controls.DatePicker
                  name="thruDate"
                  label="Thru Date*"
                  value={values.thruDate}
                  onChange={handleInputChange}
                  error={errors.thruDate}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Controls.Button
              type="submit"
              variant="contained"
              autofocus
              // disabled={!enableSubmit}
              text="SUBMIT"
            />
            <Controls.Button
              text="CANCEL"
              color="default"
              onClick={handleClose}
            />
            <PerksPageLoader show={loading} />
          </DialogActions>
        </Form>
      </Paper>
    </Dialog>
  );
}
