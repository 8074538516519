import React, { useState, useEffect } from 'react'
import { Grid, Box, Paper } from '@material-ui/core';
import Controls from "../controls/Controls"
import { useForm, Form } from "../controls/useForm"
import { useNavigate } from 'react-router-dom'
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Typography, DialogTitle, makeStyles } from '@material-ui/core'
import { Container } from '@material-ui/core';
import { toast } from "react-toastify";
import { addExternalPBM } from "../services/PerksServices";
import DialogActions from "@mui/material/DialogActions";
import { editExternalPBM } from "../services/PerksServices";
import PerksPageLoader from "../components/PerksPageLoader";
import { editExternalPbmLink } from "../services/PerksServices";


const useStyles = makeStyles(theme => ({
  dialogWrapper: {
    padding: theme.spacing(1),
    position: 'absolute',
    top: theme.spacing(5)
  },
  dialogTitle: {
    paddingRight: '10px',
    color: '#002677'

  }
}))




export default function EditPBMToList(props) {


    console.log(props);
  const classes = useStyles();
  const { addOrEdit, recordForEdit } = props
  const { onClose, open, onSubmit,refresh } = props;
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const pbmListId=props.addListId;
  

  const initialFValues = {
    extPBMId: props.pbmRowData[1],
    extPBMName: props.pbmRowData[2],
    fromDate: props.pbmRowData[3] + 'T00:00:00.0000',
    thruDate: props.pbmRowData[4] + 'T00:00:00.0000',
   

}
const handleClose = () => {
    console.log("Closing Popup");
    onClose();
  
  };
  
  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('extPBMId' in fieldValues)
      temp.extPBMId = fieldValues.extPBMId ? "" : "This field is required."
    if ('extPBMName' in fieldValues)
      temp.extPBMName = fieldValues.extPBMName ? "" : "This field is required."
    if ('fromDate' in fieldValues)
      temp.fromDate = fieldValues.fromDate ? "" : "This field is required."
    if ('thruDate' in fieldValues)
      temp.thruDate = fieldValues.thruDate ? "" : "This field is required."
    if (values.fromDate > values.thruDate)
    temp.thruDate = "Thru Date cannot be less than From date."
    


    setErrors({
      ...temp
    });


    temp.extPBMId == "" && temp.extPBMName == "" 
      ? setEnableSubmit(true) : setEnableSubmit(false);

    if (fieldValues == values) return Object.values(temp).every(x => x == "");
  }

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
   
  } = useForm(initialFValues, true, validate);

  const handleSubmit = e => {
    e.preventDefault()
    if (validate()) {
      
      setLoading(true);
      console.log("values object" + values);
      const extPBMToUpdate = {
        xp1ExtPbmList: pbmListId,
        xp2ExtPbmID: values.extPBMId,
        fromDate: values.fromDate,
        thruDate: values.thruDate,
        user: JSON.parse(sessionStorage.getItem("user-name")),
      };
      console.log(extPBMToUpdate);
      editExternalPbmLink(extPBMToUpdate).then(function(axiosResult) {
        console.log("axios result from Add External Pbm " + axiosResult);
        if (axiosResult !== null && axiosResult.data !== null) {
          console.log("Update PBM response.JSON:", {
            message: "Post successful",
            data: axiosResult
          });
          setLoading(false);
         handleClose();
         //onSubmit();
         refresh();
          toast.success(
            
            `External PBM Id ${extPBMToUpdate.xp2ExtPbmID} Updated Successfully`,
            toasterConfig
          );
        } else {
          setLoading(false);
          toast.error(`Error while updating External PBM Id ${extPBMToUpdate.xp2ExtPbmID}`, toasterConfig);
         
        }
      });
    }
  };

  


  const toasterConfig = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  };

 

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit
      })
  }, [recordForEdit])


  return (
    <Dialog
      maxWidth="sm"
      open={true}
      //PaperProps={{ variant: "elevation12" }}


    >
      <Paper>
        <Form onSubmit={handleSubmit}>

          <DialogTitle className={classes.dialogTitle}>

            <div style={{ display: 'flex' }}>
              <br></br>
              <Typography variant="h6" component="div" style={{ flexGrow: 5 }}>
                Edit External PBM
              </Typography>

            </div>
          </DialogTitle>

          <DialogContent dividers  >

            <Grid container  justifyContent="flex-start" spacing={1}>
              

            <Grid item xs={12} md={10}>
                <Typography className="label1" component="div" style={{ flexGrow: 5 }}>
                &nbsp;&nbsp;&nbsp;<b>PBM ID</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {initialFValues.extPBMId}
              </Typography>       
                </Grid>

                <Grid item xs={12} md={10}>
                <Typography className="label1" component="div" style={{ flexGrow: 5 }}>
                &nbsp;&nbsp;&nbsp;<b>PBM Name</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {initialFValues.extPBMName}
              </Typography>
                </Grid>
                </Grid>


              </DialogContent>
              <DialogContent dividers  >
                <Grid container  justifyContent="flex-start" spacing={1}>   
                <Grid item xs={12} md={10} >
                  <Controls.DatePicker
                    name="fromDate"
                    label="From Date*"
                    value={values.fromDate}
                    onChange={handleInputChange}
                    error={errors.fromDate}
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <Controls.DatePicker
                    name="thruDate"
                    label="Thru Date*"
                    value={values.thruDate}
                    onChange={handleInputChange}
                    error={errors.thruDate}
                  />
                </Grid>
                </Grid>
              </DialogContent>

                <DialogActions>
              
                  <Controls.Button
                    type="submit"
                    variant="contained"
                    autofocus
                    //disabled={!enableSubmit}
                    text="SUBMIT" 
                    />
                    <Controls.Button 
                    text="CANCEL"  
                    color="default"
                    onClick={handleClose}
                    />
                    <PerksPageLoader show={loading} />
                    </DialogActions>         
        </Form>
      </Paper>
    </Dialog>
  )
}
