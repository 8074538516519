export const getArrayDataFromIndices = (array, indexes) => {
  return indexes.map(index => array[index.dataIndex]);
};

export const formatDate = originalDate => {
  let splitDate = originalDate.split("-");
  if (splitDate.count == 0) {
    return originalDate;
  }

  let year = splitDate[0];
  let month = splitDate[1];
  let day = splitDate[2];

  let formatedDate = month + "/" + day + "/" + year;
  return formatedDate;
};
