import axios from "axios";
import resolvePromise from "./Resolve";
import userProfileJsonData from "../local/UserProfileTestData";

const getAxiosConfig = () => {
  let axiosConfig;
  return (axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
      "User-Session": JSON.parse(sessionStorage.getItem("user-session")),
      "User-Group": JSON.parse(sessionStorage.getItem("user-group")),
      "User-Name": JSON.parse(sessionStorage.getItem("user-name"))
    }
  });
};

const getAxiosGetConfig = (data) => {
  let axiosConfig;
  return (axiosConfig = {
    params: data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
      "User-Session": JSON.stringify(sessionStorage.getItem("user-session")),
      "User-Group": JSON.stringify(sessionStorage.getItem("user-group")),
      "User-Name": JSON.stringify(sessionStorage.getItem("user-name"))
    }
  });
};

const getAxiosAuthClientGetConfig = (data,sessionId) => {
  let axiosConfig;
  console.log('Data session-id ' +sessionId);
  return (axiosConfig = {
    params: data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
      "Session-ID": data['sessionId']
    }
  });
};

export const getExternalPBMs = async () => {
  let response = await resolvePromise(axios.get("/perks/getExternalPbms", getAxiosConfig()));
  return response;
};

export const getExternalPBMList = async () => {
  let response = await resolvePromise(axios.get("/perks/getExternalPbmList", getAxiosConfig()));
  return response;
};

export const addPBMList = async data => {
  console.log("PerksServices " + data);
  let response = await resolvePromise(
    axios.post("/perks/addExternalPbmList", data, getAxiosConfig())
  );
  return response;
};

export const addExternalPBM = async data => {
  let response = await resolvePromise(
    axios.post("/perks/addExternalPbm", data, getAxiosConfig())
  );
  return response;
};

export const editExternalPBM = async data => {
  console.log("PerksServices EDIT " + data);
  let response = await resolvePromise(
    axios.post("/perks/editExternalPbm", data, getAxiosConfig())
  );
  return response;
};

export const addExternalPbmLink = async data => {
  let response = await resolvePromise(
    axios.post("/perks/addExternalPbmLink", data, getAxiosConfig())
  );
  return response;
};

export const editExternalPbmLink = async data => {
  let response = await resolvePromise(
    axios.post("/perks/editExternalPbmLink", data, getAxiosConfig())
  );
  return response;
};

export const getExternalPbmIDs = async data => {
  let response = await resolvePromise(
    axios.get("/perks/Xp2Api/all", getAxiosGetConfig(data))
  );
  return response;
};

export const getExternalPbmsForList = async data => {
  console.log("data for service pbm list " + data);
  let response = await resolvePromise(
    axios.get("/perks/getexternalpbmsforlist", getAxiosGetConfig(data))
  );
  return response;
};

export const getExternalPbmsListProducts = async data => {
  console.log("service pbm list products for list id " + JSON.stringify(data));
  let response = await resolvePromise(
    axios.get("/perks/getexternalpbmlistproducts", getAxiosGetConfig(data))
  );
  return response;
};

export const getExternalPbmListFilteredProducts = async (data, multiSourceCode, status, networkId) => {
  console.log(data, multiSourceCode, status, networkId);
  data.multiSourceCode = ''
  data.status = ''
  if (multiSourceCode != null){
    data.multiSourceCode = multiSourceCode.val
  }
  if (status != null){
    data.status = status.val
  }
  let networkIdLst = []
  networkId.forEach(element => {
    networkIdLst.push(element.label)
  })
  data.networkIds = networkIdLst
  console.log("service pbm list products for list id based on filtered data ", data);
  let response = await resolvePromise(
    axios.post("/perks/getExternalPbmListFilteredProducts", data, getAxiosGetConfig())
  );
  return response;
};

export const getNetworkIdsByPbmListId = async data => {
  console.log("get network id " + JSON.stringify(data));
  let response = await resolvePromise(
    axios.get("/perks/getNetworkIdsByPbmListId" + `?pbmListId=${encodeURIComponent(data)}`)
  );
  return response;
};

export const editExternalPbmList = async data => {
  let response = await resolvePromise(
    axios.post("/perks/editExternalPbmList", data, getAxiosConfig())
  );
  return response;
};


export const inactivateexternalpbmlink = async data => {
  let response = await resolvePromise(
    axios.post("/perks/inactivateexternalpbmlink", data, getAxiosConfig())
  );
  return response;
};

export const getUserSessionData = async (data, sessionId) => {
  console.log("Inside getUserSessionData " +JSON.stringify(data));
  let response = await resolvePromise(
    axios.get("/secure/user-profile", getAxiosAuthClientGetConfig(data['session-id'], data['session-id']['sessionId']))
  );
  return response;
};

export const getUserTestData = () => {
  console.log("PerksServices json data " +JSON.stringify(userProfileJsonData));
  return userProfileJsonData;    
};
