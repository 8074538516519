const userProfileJsonData = {
    "userSubject": "User subject: CN=testuser,CN=Users,DC=ms,DC=ds,DC=uhc,DC=com",
    "decodedIdToken": {
        "sub": "CN=testuser,CN=Users,DC=ms,DC=ds,DC=uhc,DC=com",
        "aud": "Reg1Dev_RxClaimModern",
        "jti": "I1umSfEt2TRaEDKI6Git3F",
        "iss": "https://authgateway1-dev.entiam.uhg.com",
        "iat": 1642048566,
        "exp": 1642048866,
        "acr": "R1_AAL1_MS-AD-Kerberos",
        "auth_time": 1642048566,
        "msid": "testuser",
        "roles": [
            "CN=ORX_Portals_SCL_Dev_DL,OU=Dist-Lists,OU=Messaging,OU=UHT,OU=UHG,DC=ms,DC=ds,DC=uhc,DC=com",
            "CN=RXDV1_Prescriber_FullAccess,CN=Users,DC=ms,DC=ds,DC=uhc,DC=com",
            "CN=RXDV1_QA_DG_ALL,CN=Users,DC=ms,DC=ds,DC=uhc,DC=com",
            "CN=RXDV1_DEV_DG_ALL,CN=Users,DC=ms,DC=ds,DC=uhc,DC=com",
            "CN=RXCL1_OCM_DG_ALL,CN=Users,DC=ms,DC=ds,DC=uhc,DC=com",
            "CN=RXDV1_DEV_DG_ALL_Carrier_Access,CN=Users,DC=ms,DC=ds,DC=uhc,DC=com",
            "CN=PERKS_DEV_ADMIN,CN=Users,DC=ms,DC=ds,DC=uhc,DC=com"

        ],
        "given_name": "Test",
        "family_name": "User",
        "email": "test.user@optum.com"
    },
    "oAuthToken": {
        "access_token": "",
        "refresh_token": "",
        "id_token": "",
        "token_type": "Bearer",
        "expires_in": 1799000,
        "expires_on": 1642050365196
    }
}

export default userProfileJsonData;