import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Typography, DialogTitle, makeStyles } from "@material-ui/core";
import { Grid, Box, Paper } from "@material-ui/core";
import Controls from "../controls/Controls";
import MUIDataTable from "mui-datatables";
import getMuiTheme from "../assets/muitheme";
import { ThemeProvider } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import PerksPageLoader from "../components/PerksPageLoader";
import { formatDate } from "../utils/perksutil";
import { getExternalPbmListFilteredProducts } from "../services/PerksServices";
import { getNetworkIdsByPbmListId } from "../services/PerksServices";
import { useForm, Form } from "../controls/useForm"
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2)
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}));


const BootstrapDialogTitle = props => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle
            sx={{ m: 0, p: 2, paddingRight: "10px", color: "#002677" }}
            {...other}
        >
            {children}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
};

const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        padding: theme.spacing(1),
        position: "absolute",
        top: theme.spacing(5)
    },
    dialogTitle: {
        paddingRight: "10px",
        color: "#002677"
    }
}));

const initialFValues = {
    // networkId: '',
    ndcGpiCode: '',
    quantityLow: '',
    extPBMId: '',
}

const multiSourceCodeData = [
    { label: 'ALL', val: 'A'},
    { label: 'Generic', val: 'Y'},
    { label: 'Multi-Source Not Generic', val: 'M'},
    { label: 'Non-subst Multi-Source Br', val: 'Z'},
    { label: 'Original, Generics Avail', val: 'O'},
    { label: 'Price as N, Report as Y', val: 'X'},
    { label: 'Single-Source Not Generic', val: 'N'},
];

const statusData = [
    { label: 'Active', val: 'A'},
    { label: 'Inactive', val: 'I'},
];

export default function ProductsDialog(props) {
    const classes = useStyles();
    const [pbmListProducts, setPbmListProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const { onClose, pbmRowData } = props;

    const [networkIdData, setNetworkIdData] = useState([]);
    const [networkId, setNetworkId] = useState([]);
    const [multiSourceCode, setMultiSourceCode] = useState("");
    const [status, setStatus] = useState("");
    const [errs, setErrs] = useState({});

    useEffect(() => {
        // get list of all networkId at first render
        getNetworkId();
    }, []);

    // function to get all networkId 
    function getNetworkId() {
        getNetworkIdsByPbmListId(props.pbmRowData[1]).then(function (result) {
            console.log(result);
            var networkIdLst = result.data.reduce((acc, car) => {
                acc.push({ label: car });
                return acc;
            }, []);
            setNetworkIdData(networkIdLst);
            console.log(networkIdLst);
            return;
        });
    }

    function isNumeric(value) {
        return /^-?\d+(\.\d+)?$/.test(value);
    }

    function validate(){
        let temp = {}
        if (values.ndcGpiCode.length > 0 && values.ndcGpiCode.length < 3){
            temp.ndcGpiCode = "At least 3 characters are required!"
        }
        if(values.quantityLow.length != 0 && !isNumeric(values.quantityLow)) {
            temp.quantityLow = "Invalid. Numeric is required!"
        }
        return temp
    }

    function onSearch(){
        let errs = validate()
        setErrs(errs)
        console.log(errs, errors)
        if (errs["ndcGpiCode"] === undefined && errs["quantityLow"] === undefined) {
            console.log("Loading External PBM List Products");
            setIsLoading(true);
            values.extPBMId = props.pbmRowData[1]
            getExternalPbmListFilteredProducts(values, multiSourceCode, status, networkId).then(function (axiosResult) {
                console.log("response.JSON:", {
                    message: "Request received",
                    data: axiosResult.data
                });
                setPbmListProducts(axiosResult.data);
                setIsLoading(false);
            });
        }
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
    
    } = useForm(initialFValues, true, validate);

    const data = {
        pbmListId: pbmRowData[1]
    };

    const columns = [
        {
            name: "fromDate",
            label: "From Date",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value && formatDate(value);
                }
            }
        },

        {
            name: "thruDate",
            label: "Thru Date",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value && formatDate(value);
                }
            }
        },
        {
            name: "networkId",
            label: "Network ID",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true
            }
        },
        {
            name: "productType",
            label: "Product Type",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value && value === "G" ? "GPI" : "NDC";
                }
            }
        },

        {
            name: "ndcGpiCode",
            label: "NDC GPI CODE",
            options: {
                filter: true,
                sort: false,

            }
        },
        {
            name: "multiSourceCode",
            label: "Multi Source Code",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "quantityLow",
            label: "Quantity Low",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "quantityHigh",
            label: "Quantity High",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "extPbmId",
            label: "Ext PBM ID",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value && value === "A" ? "Active" : "Inactive";
                }
            }
        },
        {
            name: "addDateIso",
            label: "Add Date",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "chgDateIso",
            label: "Change Date",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "chgUserName",
            label: "User",
            options: {
                filter: false,
                sort: false
            }
        }
    ];

    const changeBGColor = e => {
        e.target.style.background = "rgb(245,245,245)";
    };

    const revertBGColor = e => {
        e.target.style.background = "white";
    };

    const options = {
        selectableRows: "none",
        selectableRowsHeader: true,
        selectToolbarPlacement: "replace",
        resizeableColumns: true,
        download: true,
        print: false,
        filterable: false,
        filter: true,
        search: true,
        pagination: true,
        viewColumns: true,
        filterType: "multiselect",
        responsive: "vertical",
        expandableRows: false,
        rowsPerPage: [10],
        rowsPerPageOptions: [10, 25, 50],
        jumpToPage: true,
        customToolbarSelect: () => {},
        textLabels: {
            pagination: {
                next: "Next >",
                previous: "< Previous",
                rowsPerPage: "Total rows Per Page"
            },
            body: {
                noMatch: isLoading ? (
                    <PerksPageLoader isLoading />
                ) : (
                    "No External PBM List Product data to display"
                )
            }
        }
    };

    // useEffect(() => {
        // console.log(props.pbmRowData[1]);
        // values.extPBMId = 
        // setValues({
        //     ...values,
        //     [values.extPBMId]: props.pbmRowData[1]
        // })
        // setIsLoading(true);

        // getExternalPbmsListProducts(data).then(function (axiosResult) {
        //     console.log("response.JSON:", {
        //         message: "Request received",
        //         data: axiosResult.data
        //     });
        //     setPbmListProducts(axiosResult.data);
        //     setIsLoading(false);
        // });
    // }, []);

    return isLoading ? (
        <PerksPageLoader show={isLoading} />
    ) : (
        <Dialog
            fullWidth
            maxWidth="lg"
            open={true}
            PaperProps={{ variant: "elevation14" }}
        >
            <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: "flex" }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 5 }}>
                        External PBM List Products
                    </Typography>
                </div>
            </DialogTitle>
            <Divider style={{ width: "100%" }} />

            < br />
            <Grid container>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Grid
              item
              xs={30}
              justify="left"
              alignItems="left"
              style={{ marginLeft: "10%", marginTop: "12px" }}
            >
              <br></br>
              <Grid item xs={12}>
                <DialogContentText className={classes.dialogTitle}>
                  <div style={{ display: "flex", marginLeft: "28px" }}>
                    <br></br>
                    <Grid item xs={6}>
                      <Typography variant="h6" component="div" display="inline">
                        List ID
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        display="inline"
                      >
                        {data.pbmListId}
                      </Typography>
                    </Grid>
                  </div>
                </DialogContentText>
              </Grid>
              </Grid>
              </Grid>
              </Grid>
              </Grid>

            <br></br>

            <Divider style={{ width: "100%" }} />

            <br />
            <br />
            <div style={{ marginLeft: "40px" }}>

                <Grid container spacing={4} justifyContent="center" alignItems="center" direction="row">
                    <Grid item xs="auto" >
                        <Controls.Input
                            name="extPBMId"
                            label="Ext PBM Id"
                            value={props.pbmRowData[1]}
                            onChange={handleInputChange}
                            error={errors.extPBMId}
                            disabled
                        />
                    </Grid>

                    <Grid item style={{ width: "25%" }} >
                        <Controls.SelectMultiple
                            dropDownOptions={networkIdData}
                            onChange={(e, value) => {
                                setNetworkId(value)
                            }}
                            name="networkId"
                            label="Network ID"
                            value={networkId}
                            popupIcon={<FilterAltOutlinedIcon/>}
                        />
                    </Grid>

                    <Grid item spacing={4} justifyContent="center" alignItems="center" direction="col">
                        <Grid item xs="auto">
                            <Controls.Input
                                inputProps={{
                                    minLength: 3,
                                    maxLength: 14
                                }}
                                name="ndcGpiCode"
                                label="NDC GPI Code"
                                value={values.ndcGpiCode}
                                onChange={handleInputChange}
                                error={errors.bin}
                            />
                        </Grid>
                        <Grid item xs="auto">
                            {errs["ndcGpiCode"] !== undefined ? <p style={{color: "red"}}>{errs["ndcGpiCode"]}</p> : null}
                        </Grid>
                    </Grid>

                </Grid>
                        
                <Grid container spacing={4} justifyContent="center" alignItems="center" direction="row" style={{marginBottom: "10px"}}>
                    <Grid item spacing={4} justifyContent="center" alignItems="center" direction="col">
                        <Grid item xs="auto">
                            <Controls.Input
                                name="quantityLow"
                                label="Quantity Low"
                                value={values.quantityLow}
                                onChange={handleInputChange}
                                error={errors.quantityLow}
                            />
                        </Grid>
                        <Grid item xs="auto">
                            {errs["quantityLow"] !== undefined ? <p style={{color: "red"}}>{errs["quantityLow"]}</p> : null}
                        </Grid>
                    </Grid>

                    <Grid item style={{width: "200px"}}>
                        <Controls.SelectSingle
                            data={statusData}
                            placeholder="Status"
                            name="status"
                            value={status}
                            onChange={(e, value) => {
                                setStatus(value);
                            }}
                        />
                    </Grid>

                    <Grid item style={{width: "300px"}}>
                        <Controls.SelectSingle
                            data={multiSourceCodeData}
                            placeholder="Multisource Code"
                            name="multiSourceCode"
                            value={multiSourceCode}
                            onChange={(e, value) => {
                                setMultiSourceCode(value);
                            }}
                        />
                    </Grid>

                    <Grid item xs="auto" style={{marginLeft: "20px"}}>
                        <Controls.Button
                            text="SEARCH"
                            color="default"
                            onClick={() => onSearch()}
                        />
                    </Grid>
                </Grid>

                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title="External PBM List Products"
                        data={pbmListProducts}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>

            </div>

            <br />

            <Grid container justify="center" alignItems="center">
                <Grid item xs={50} justify="center" alignItems="center">
                    <br></br>
                    <Controls.Button
                        text="CANCEL"
                        color="default"
                        onClick={() => onClose()}
                    />
                </Grid>
            </Grid>
        </Dialog>
    );
}
