import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { getUserTestData, getUserSessionData } from "../services/PerksServices";
import axios from "axios";

const getAxiosGetConfig = (data) => {
    let axiosConfig;
    return (axiosConfig = {
        params: data,
        headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
        "User-Session": JSON.stringify(sessionStorage.getItem("user-session")),
        "User-Group": JSON.stringify(sessionStorage.getItem("user-group")),
        "User-Name": JSON.stringify(sessionStorage.getItem("user-name"))
        }
    });
};

export const ValidateLogin = (props) => {

    let { sessionId } = useParams();
    let currentPerksRole;
    let hasValidRole;
    let retrievedUserData;
    let authType;
    let tenantId;

    const navigate = useNavigate();

    const getSessionIdFromURL = () => {
        if (process.env.REACT_APP_API_ENV === 'local') {
            sessionId = sessionId + "&tenantId=RXDV1_QUA";
        }
        let queryStringAfterSplit = sessionId.split('&');
        authType = queryStringAfterSplit[2].split('=')[1]
        tenantId = queryStringAfterSplit[3].split('=')[1]
        
        console.log("Query Param String after removing & " + queryStringAfterSplit);

        let queryParamsSplitCommas = queryStringAfterSplit[1].toString().split(',');
        const [prop, value] = queryParamsSplitCommas.toString().split('=');
        console.log(prop);
        console.log(value);

        return { sessionId: value, authType, tenantId };
    }

    // function perksRoleFilter(role) {
    //     console.log("this is my env ", process.env.REACT_APP_API_ENV)
    //     switch (process.env.REACT_APP_API_ENV) {
    //         case 'dev':
    //             console.log("Dev Role Filter");
    //             return role.includes("PERKS_DEV");
    //         case 'local':
    //             console.log("Local Role Filter");
    //             return role.includes("PERKS_DEV");
    //         case 'qa':
    //             console.log("QA Role Filter");
    //             return role.includes("PERKS_QA");
    //         case 'stg':
    //             console.log("STG Role Filter");
    //             return role.includes("PERKS_STG");
    //         case 'prd':
    //             console.log("PROD Role Filter");
    //             return role.includes("PERKS_PRD");
    //         default:
    //             console.log("Not Authorized Filter");
    //             return role.includes("Not Authorized");
    //     }
        
    // }
    // const getUserRole = (userData) => {
    //     console.log("Profile Data in user role" + JSON.stringify(userData));
    //     let userRoles = userData.roles;
    //     let perksRoles = userData.roles.filter(perksRoleFilter);
    //     if (perksRoles.length !== 0) {
    //         console.log("Valid role found. " + perksRoles[0]);
    //         hasValidRole = true;
    //         currentPerksRole = perksRoles[0];
    //         console.log("Current Perks Role " + currentPerksRole);
    //     }
    //     console.log("Filtered Roles array " + perksRoles);
    // }

    const setSessionData = (extractedSessionId) => {
        // If user is authenticated or verified, store infromation in session object
        // console.log("Has valid role " + hasValidRole);
        // if (hasValidRole) {
        console.log("Valid User");
        sessionStorage.setItem('user-session', JSON.stringify(extractedSessionId));
        // sessionStorage.setItem('user-group', JSON.stringify(currentPerksRole));
        sessionStorage.setItem('user-name', JSON.stringify(retrievedUserData.given_name));
        props.showNavigationLinks(true);
        //     navigate('/Welcome');
        // } else {
        //     props.showNavigationLinks(false);
        //     navigate('/notauthorized');
        // }
    }

    const verifyLogin = (extractedSessionId) => {

        if (window.location.hostname === "localhost") {
            let userData = getUserTestData();
            console.log("Using User Test Profile Data ", (userData));
            retrievedUserData = userData['decodedIdToken'];
            // getUserRole(retrievedUserData);
            setSessionData(extractedSessionId);
        } else {
            const data = {
                "session-id": extractedSessionId
            }
            console.log(data)
            getUserSessionData(data, extractedSessionId).then(function (axiosResult) {
                console.log("response.JSON:", {
                    message: "User Session data received",
                    data: JSON.stringify(axiosResult.data)
                });
                retrievedUserData = axiosResult.data['decodedIdToken'];
                console.log("User Profile Lookup Data " + retrievedUserData);
                // getUserRole(retrievedUserData);
                setSessionData(extractedSessionId);
            });
        }


    }
    
    const getUserAbilites = async (sessionId) => {
        let host = tenantId.split("_")[0]
        let data = { sessionId: sessionId.sessionId, authType, tenantId }
        let response = await axios.get("/perks/userRole", getAxiosGetConfig(data));
        props.setUserRole(response.data)
        props.setHost(host)
    }


    useEffect(() => {

        let extractedSessionId = getSessionIdFromURL();
        verifyLogin(extractedSessionId);
        getUserAbilites(extractedSessionId);

    }, []);



    return null;
}